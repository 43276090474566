
h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
  }
  
  
  .error-page-section {
    /* background-image: url("../images/background-3.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    position: relative;
  }
  .error-page-section .content-detail {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .error-page-section .content-detail .sub-title, .error-page-section .content-detail .detail-text {
    display: block;
    color: #322d2d;
    text-align: center;
  }
  .error-page-section .content-detail .sub-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
  }
  .error-page-section .content-detail .global-title {
    display: block;
    color: #3b7b8aa3;
    font-size: 88px;
    font-weight: 800;
    text-transform: capitalize;
  }
  .error-page-section .content-detail .global-title span {
    -webkit-animation: animateblur 1s linear forwards;
            animation: animateblur 1s linear forwards;
  }
  .error-page-section .content-detail .global-title span:nth-child(1) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .error-page-section .content-detail .global-title span:nth-child(2) {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  .error-page-section .content-detail .global-title span:nth-child(3) {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  @-webkit-keyframes animateblur {
    0% {
      opacity: 0;
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    100% {
      opacity: 1;
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @keyframes animateblur {
    0% {
      opacity: 0;
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    100% {
      opacity: 1;
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  .error-page-section .content-detail .back-btn {
    margin-top: 15px;
  }
  .error-page-section .content-detail .back-btn .btn {
    padding: 8px 15px;
    color: #6f8c7c;
    background-color: #adf3ce;
    border: 1px solid #b4e4ca;
    border-radius: 30px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 5px 20px #e2e0e0;
            box-shadow: 0px 5px 20px #e2e0e0;
    z-index: 5;
    position: relative;
  }
  .error-page-section .content-detail .back-btn .btn:hover {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /*background-color: #9fd2b7;*/
    /*color: #fff;*/
    border: 1px solid transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #686968;
  }
  .error-page-section .content-detail .parallax {
    position: absolute;
  }
  .error-page-section .content-detail #shape1, .error-page-section .content-detail #shape2, .error-page-section .content-detail #shape3, .error-page-section .content-detail #shape4, .error-page-section .content-detail #shape5, .error-page-section .content-detail #shape6, .error-page-section .content-detail #shape7 {
    opacity: 0.7;
  }
  .error-page-section .content-detail #shape1 {
    bottom: 20px;
    right: 500px;
  }
  .error-page-section .content-detail #shape2 {
    top: 75px;
    right: 581px;
  }
  .error-page-section .content-detail #shape4 {
    top: 40px;
    left: 290px;
  }
  .error-page-section .content-detail #shape5 {
    bottom: 138px;
    left: 170px;
  }
  .error-page-section .content-detail #shape3 {
    top: 300px;
    right: 250px;
  }
  .error-page-section .content-detail #shape6 {
    top: 220px;
    right: 100px;
  }
  .error-page-section .content-detail #shape7 {
    bottom: 10px;
    right: 10px;
  }

  .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    background: #3b7b8aa3;
    color: white;
}